import "./src/fonts/fonts.css";
import * as React from 'react'
import { PreviewStoreProvider } from 'gatsby-source-prismic'

export const wrapRootElement = ({ element }) => (
  <PreviewStoreProvider initialEnabled={true}>{element}</PreviewStoreProvider>
)

// always return to top of page on page navigation
export const shouldUpdateScroll = () => { return [0, 0] }

